import * as yup from "yup";
import yupPassword from "yup-password";

yupPassword(yup);

const requiredMessage = "This field is required";

const password = yup
  .string()
  .min(8, "Password must be at least 8 characters")
  .minUppercase(1, "Password must be at least 1 uppercase letter")
  .minNumbers(1, "Password must be at least 1 number")
  .required(requiredMessage);

export const updatePasswordSchema = yup.object({
  newPassword: password,
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Password does not matched")
    .required(requiredMessage),
});
