import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import { confirmPasswordReset, getAuth, verifyPasswordResetCode, checkActionCode } from "firebase/auth";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import Password from "../components/Input/Password";
import { firebaseApp } from "../config/firebase";
import { updatePasswordSchema } from "../helpers/utils/formSchema";
import useQueryParam from "../hooks/useQueryParam";
import Layout from "./_Layout";

const ActionCodeError = {
  EXPIRED: "auth/expired-action-code",
  INVALID_CODE: "auth/invalid-action-code",
  USER_DISABLED: "auth/user-disabled",
  USER_NOT_FOUND: "auth/user-not-found",
};

export default function ResetPassword() {
  const { control, handleSubmit, formState } = useForm({ resolver: yupResolver(updatePasswordSchema) });

  const query = useQueryParam();
  const actionCode = query.get("oobCode");
  const continueUrl = query.get("continueUrl");
  const auth = getAuth(firebaseApp);
  const history = useHistory();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleResetError = _error => {
    setIsError(true);
    switch (_error.code) {
      case ActionCodeError.INVALID_CODE:
        setErrorMessage("Your password reset link is no longer valid");
        break;
      case ActionCodeError.EXPIRED:
        setErrorMessage("Your password reset link has expired");
        break;
      case ActionCodeError.USER_DISABLED:
        setErrorMessage("User has been disabled");
        break;
      case ActionCodeError.USER_NOT_FOUND:
        setErrorMessage("User not found");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!actionCode) {
      history.replace("/");
    } else {
      checkActionCode(auth, actionCode).catch(handleResetError);
    }
  }, [actionCode, history, auth]);

  const handleOnResetPassword = ({ confirmNewPassword }) => {
    const handleResetSuccess = () => {
      query.set("mode", "resetPasswordComplete");

      if (continueUrl) {
        history.replace({
          search: query.toString(),
          state: { continueUrl },
        });
      } else {
        history.replace("/");
      }
    };

    handleResetPassword({
      auth,
      actionCode,
      newPassword: confirmNewPassword,
      onSuccess: handleResetSuccess,
      onError: handleResetError,
    });
  };

  return (
    <Layout fullScreen title="Reset Password">
      <form onSubmit={handleSubmit(handleOnResetPassword)}>
        <Grid container>
          <Grid item py={1} xs={12}>
            <Password
              fullWidth
              name="newPassword"
              defaultValue=""
              type="password"
              variant="outlined"
              error={!!formState.errors?.newPassword}
              control={control}
              placeholder="Enter new password"
              helperText={formState.errors?.newPassword?.message || null}
            />
          </Grid>
          <Box item component={Grid} paddingY={1} xs={12}>
            <Password
              fullWidth
              defaultValue=""
              variant="outlined"
              error={!!formState.errors?.confirmNewPassword}
              placeholder="Confirm new password"
              helperText={formState.errors?.confirmNewPassword?.message || null}
              name="confirmNewPassword"
              type="password"
              control={control}
            />
          </Box>

          <Grid item xs={12}>
            {isError && (
              <Typography color="error.light" textAlign="center">
                {errorMessage}
              </Typography>
            )}

            <Box display="flex" justifyContent="space-around" width="100%" marginY={2}>
              <LoadingButton
                fullWidth
                disableRipple
                loading={formState.isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
                disabled={formState.isSubmitting || isError}
              >
                Reset
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Layout>
  );
}

function handleResetPassword({ auth, actionCode, newPassword, onSuccess, onError }) {
  verifyPasswordResetCode(auth, actionCode)
    .then(_email => {
      confirmPasswordReset(auth, actionCode, newPassword).then(onSuccess).catch(onError);
    })
    .catch(onError);
}
