import { createTheme } from "@mui/material";

import Colors from "./Color";

const primaryColor = "#0088ED";

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    background: {
      default: "#F5F5F5",
    },
  },
  typography: {
    fontFamily: ["campton-book"].join(","),
    caption: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: primaryColor,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "campton-medium",
          color: Colors.white,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none",
          backgroundColor: "rgba(255, 255, 255, 0.05)",
          borderRadius: 8,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: Colors.white,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          color: Colors.white,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: Colors.white,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: Colors.primary,
          color: Colors.white,
          fontFamily: "campton-medium",
          fontSize: 16,
          padding: 10,
          borderRadius: 8,
          "&:hover": {
            background: Colors.primary,
            opacity: 0.5,
          },
          "&:disabled": {
            background: Colors.grey,
          },
        },
      },
    },
  },
});

export default theme;
