import useQueryParam from "../hooks/useQueryParam";
import LandingPage from "../pages/LandingPage";
import ResetPasswordPage from "../pages/PasswordResetPage";
import ResetPasswordCompletePage from "../pages/ResetPasswordCompletePage";
import VerifyEmailCompletePage from "../pages/VerifyEmailCompletePage";
import VerifyEmailPage from "../pages/VerifyEmailPage";

const modes = {
  // from firebase
  resetPassword: <ResetPasswordPage />,
  verifyEmail: <VerifyEmailPage />,

  // custom
  resetPasswordComplete: <ResetPasswordCompletePage />,
  verifyEmailComplete: <VerifyEmailCompletePage />,
};

const RenderMode = () => {
  const query = useQueryParam();
  const mode = query.get("mode");
  return modes[mode] || <LandingPage />;
};

export default RenderMode;
