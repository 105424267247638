import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";

import RenderMode from "./components/RenderMode";
import theme from "./theme";

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RenderMode />
      </ThemeProvider>
    </Router>
  );
};

export default App;
