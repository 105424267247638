import { TextField } from "@mui/material";
import { useController } from "react-hook-form";

export default function Input({ control, name, rules, defaultValue = "", inputRef, ...props }) {
  const {
    field: { _ref, ...inputProps },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return <TextField {...inputProps} {...props} inputRef={inputRef} />;
}
