import { styled } from "@mui/material";

import bgImage from "../assets/bg.svg";
import logo from "../assets/Logo/360Live-460.png";
import Colors from "../Color";

const Container = styled("div")({
  background: Colors.blue,
  backgroundImage: `url(${bgImage})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "230%",
  width: "100vw",
  height: "100vh",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const Logo = styled("img")({
  width: "30%",
});

const LandingPage = () => {
  return (
    <Container>
      <Logo
        sx={{
          width: {
            xs: "60%",
            lg: "30%",
          },
        }}
        src={logo}
        alt="360Live logo"
      />
    </Container>
  );
};

export default LandingPage;
