const Colors = {
  background: {
    dark: "#111A41",
    light: "#EFEFEF",
  },
  inputField: { dark: "#EEEEF012", light: "#111A410D" },
  border: "#FFFFFF29",
  text: {
    dark: "#FFFFFF",
    light: "#111A41",
  },
  card: { dark: "#EEEEF012", light: "#111A410D" },
  bottomBar: "#02040E80",
  primary: "#24D74C",
  secondary: "#00DDCD",
  red: "#FF5556",
  green: "#24D74C",
  blue: "#111A41",
  blue30: "#111A414D",
  turquoise: "#00DDCD",
  turquoise30: "#00DDCD4D",
  yellow: "#F7C943",
  pink: "#FF91B0",
  smokeWhite: "#EFEFEF",
  white: "#FFFFFF",
  white05: "#FFFFFF0D",
  white10: "#FFFFFF1A",
  white20: "#FFFFFF33",
  white30: "#FFFFFF4D",
  white32: "#FFFFFF52",
  white50: "#FFFFFF80",
  grey: "grey",
  darkGrey: "#48484A",
  black: "#000000",
};

export default Colors;
