import React from "react";

import Colors from "../../Color";

export default function CheckedIcon() {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78 40C78 60.9868 60.9868 78 40 78C19.0132 78 2 60.9868 2 40C2 19.0132 19.0132 2 40 2C60.9868 2 78 19.0132 78 40ZM34.0094 52.6063C34.6597 53.0795 35.451 53.3347 36.2638 53.3333L36.2672 53.329C36.8469 53.3307 37.4192 53.2027 37.9397 52.955C38.4602 52.7074 38.9149 52.3467 39.2686 51.901L54.5558 32.5559C55.1637 31.7851 55.4317 30.8117 55.3009 29.8494C55.1701 28.8871 54.6512 28.0144 53.8581 27.4229L53.7753 27.5316L53.8559 27.4229L53.8548 27.4221C53.4617 27.1297 53.0131 26.9152 52.5346 26.791C52.0561 26.6668 51.557 26.6352 51.066 26.6982C50.5749 26.7611 50.1014 26.9173 49.6725 27.1578C49.2436 27.3984 48.8678 27.7185 48.5664 28.1L35.5695 44.5541L30.0694 40.4612C29.6761 40.1687 29.2273 39.9541 28.7486 39.8299C28.2699 39.7056 27.7707 39.6741 27.2794 39.737C26.7881 39.8 26.3144 39.9563 25.8854 40.1969C25.4563 40.4375 25.0803 40.7578 24.7788 41.1394C24.4773 41.521 24.2562 41.9566 24.1282 42.4211C24.0001 42.8857 23.9676 43.3701 24.0325 43.8469C24.0974 44.3236 24.2584 44.7833 24.5064 45.1996C24.7543 45.616 25.0844 45.9809 25.4776 46.2735L33.9691 52.5769L34.0094 52.6063Z"
        fill={Colors.green}
      />
    </svg>
  );
}
